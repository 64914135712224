import { get, post, update, remove } from '~/services/http-requests'

const _BS_SERVICE = 'producer_services'

/*
 **** GET ****
 */

function fetchAllServices(): Promise<IService[]> {
    return get({ baseProperty: _BS_SERVICE })
}

async function fetchSingleService(serviceId: IService['id']): Promise<IService> {
    const [first] = await get({ baseProperty: _BS_SERVICE, fullUrl: `${_BS_SERVICE}.json?id=${serviceId}` })
    return first
}

/*
 **** POST ****
 */
function createService(service: IService): Promise<IService> {
    // remove '.svg' from icon name
    // service.icon.substring(0, service.icon.length - 4)
    return post({ baseProperty: _BS_SERVICE, dispatchParams: service })
}

/*
 **** PATCH ****
 */
function updateService(service: IService): Promise<IService> {
    return update({ baseProperty: _BS_SERVICE, fullUrl: `${_BS_SERVICE}/${service.id}/`, dispatchParams: service })
}

/*
 **** DELETE ****
 */
function removeService(serviceId: IService['id']) {
    return remove({ baseProperty: _BS_SERVICE, fullUrl: `${_BS_SERVICE}/${serviceId}/` })
}

export { fetchAllServices, fetchSingleService, createService, updateService, removeService }
