


























































import { Vue, Prop, Component } from 'vue-property-decorator'
// @ts-ignore
import { quillEditor } from 'vue-quill-editor'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { fetchSingleService, updateService, createService, removeService } from '#/api/service'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { TOOLBAR_SETTINGS } from '#/plugins/vue-quill-editor'

const serviceIcons = [
	'ServiceAlbum',
	'ServiceAlbums',
	'ServiceCamera',
	'ServiceCd',
	'ServiceGuitar1',
	'ServiceGuitar2',
	'ServiceHandMoney',
	'ServiceHandshake',
	'ServiceHeadphones',
	'ServiceMicrophone1',
	'ServiceMicrophone2',
	'ServiceMicrophone3',
	'ServiceMixing1',
	'ServiceMixing2',
	'ServiceMixing3',
	'ServiceMixing4',
	'ServiceMixing5',
	'ServiceMusicFile',
	'ServiceMusicFolder',
	'ServiceNote',
	'ServicePencil1',
	'ServicePencil2',
	'ServicePencil3',
	'ServicePicture',
	'ServiceRadio',
	'ServiceSpeakers'
]

@Component({ components: { VLoadSpinner, quillEditor } })
export default class ServicePage extends Vue {
	@Prop({ type: Number }) id: number

	editorOption = { modules: { toolbar: TOOLBAR_SETTINGS } }
	isLoading = true
	existsOnDatabase = false
	serviceIcons = serviceIcons
	// @ts-ignore
	service: IService = {}
	isDropdownOpen = false

	get isNewRoute(): boolean {
		return !this.id
	}

	@ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void

	closeDropdown() {
		this.isDropdownOpen = false
	}

	setNewIcon(icon: string) {
		this.service.icon = icon
		this.closeDropdown()
	}

	onEditorChange({ html }: { html: string }) {
		this.service.description = html
	}

	async _fetchService(id: number) {
		this.isLoading = true
		try {
			this.service = await fetchSingleService(this.id)
		} finally {
			this.isLoading = false
		}
	}

	// create or updates service
	async saveAllChanges() {
		try {
			if (this.existsOnDatabase) await updateService(this.service)
			else {
				const newService = await createService(this.service)
				Vue.router.push({ name: 'ServiceEdit', params: { id: Number(newService.id) } })
			}
		} catch (error) {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
		} finally {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
		}
	}

	async deleteService() {
		try {
			await removeService(this.service.id)
		} catch (error) {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
		} finally {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
			Vue.router.push({ name: 'Services' })
		}
	}

	openConfirmActionModal() {
		this.SET_ACTIVE_MODAL({ modal: ModalType.ConfirmAction, callback: () => this.deleteService() })
	}

	created() {
		this.existsOnDatabase = !this.isNewRoute
		if (this.existsOnDatabase) this._fetchService(this.id)
		else {
			this.isLoading = false
			this.service.icon = this.serviceIcons[16]
		}
	}
}
