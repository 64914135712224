





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ElitepageStore } from '@elitepage/store/elitepage'
import PServiceBox from '@elitepage/components/PServiceBox.vue'
import { fetchService } from '@elitepage/api/services'
import Service from '#/views/Service.vue'

@Component({ components: { Service, PServiceBox } })
export default class SoundKits extends Vue {
    @Prop({ type: String }) producerSlug!: IProducer['slug']
    @Prop({ type: Number }) id!: ISoundKit['id']

    service: IService = null

    @ElitepageStore.State('elitepage') elitepage: IElitepage
    @ElitepageStore.State('producer') producer: IProducer

    @ElitepageStore.Action('fetchSingleProducer') fetchSingleProducer: (producerSlug: IProducer['slug']) => void

    async mounted() {
        this.service = await fetchService(this.producer.id, this.id)
    }
}
